
import { ref, defineComponent } from 'vue'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import CardBlueBorder from '@/views/components/card/CustomBlueBorder.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Email Notification',
  components: {
    InputSwitch,
    InputText,
    ButtonPrimary,
    CardBlueBorder
  },
  data() {
    return {
      checked: ref(false),
      email: ref('')
    }
  },
  methods: {
    handleSendNotification() {
      this.checked = !this.checked
    },
    handleInputEmail(e: any) {
      this.email = e.target.value
    }
  },
  setup() {
    const store = useStore()
    store.dispatch('ui/setBreadcrumbs', [
      {
        title: 'Pengaturan',
        routeName: null
      },
      {
        title: 'Notifikasi Email',
        routeName: 'email-notification'
      },
    ])

    return {
      store
    }
  }
})

